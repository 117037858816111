html {
  box-sizing: border-box;
}
body,
html {
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  //font-family: 'Montserrat', sans-serif;
  //font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@import "shared/bootstrap/bootstrap-grid.scss";

button {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #21593c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #21593c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.Toastify__toast-body {
  white-space: pre-line;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.items-flex-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}

form {
  h2 {
    font-size: 19px;
    margin-bottom: 20px;
  }
}

.form-group {
  padding: 10px 0;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-wrapper input,
  > input,
  > textarea {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 10px 13px;
    font-size: 15px;
    font-family: "Rubik", sans-serif;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.3) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
  input:read-only,
  textarea:read-only {
    background-color: #ebebe4;
  }
  .input-error-message {
    color: #ea5252;
    font-size: 14px;
    font-weight: bold;
  }
}

.shadow {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
    0 3px 6px 0 rgba(0, 0, 0, 0.12);
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}
.mt-5 {
  margin-top: 10px;
}
.mt-10 {
  margin-top: 20px;
}
.mb-5 {
  margin-bottom: 10px;
}
.mb-10 {
  margin-bottom: 20px;
}
.ml-5 {
  margin-left: 10px;
}
.ml-10 {
  margin-left: 20px;
}
.mr-5 {
  margin-right: 10px;
}
.mr-10 {
  margin-right: 20px;
}
.p-20 {
  padding: 20px;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
.d-none {
  display: none !important;
}
.w-100 {
  width: 100%;
}
.w-100-mobile {
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
